import React, { useState, useEffect } from 'react';
import { I18n } from '@deity/falcon-i18n';
import { Preloader } from '../../ui/Preloader/Preloader';
import { Creator } from '../../elements/Creator/Creator.js';
import { NewsletterSubscriptionStepsQuery } from '../Account/Newsletter/NewsletterSubscriptionQuery';
import { SetSaveSubscriptionInfoMutation } from '../Account/Newsletter/NewsletterSubscriptionMutation';
import { useConfirmNewsletterMutation } from '../../sections/Newsletter/NewsletterMutation';
import { useGlobalStateValue } from '../../helpers/GlobalState/GlobalState';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import { getUserIp } from '../../elements/Geoip/Geoip';
import { NewsletterStepsTracking } from '../../elements/Tracking/GoogleTagManager';
import NewsletterSubscriberConfirm from './NewsletterSubscriberConfirm';
import NewsletterCreatorFirstStep from './Steps/NewsletterCreatorFirstStep';
import NewsletterCreatorQuestionStep from './Steps/NewsletterCreatorQuestionStep';
import NewsletterCreatorConfirmationStep from './Steps/NewsletterCreatorConfirmationStep';

const NewsletterCreator = withStoreConfiguration(({ storeConfiguration }) => {
  const [, dispatch] = useGlobalStateValue();
  const moduleEnabled = storeConfiguration?.asam_newslettersubscription?.general?.enabled;
  const [confirmNewsletter] = useConfirmNewsletterMutation();
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(true);
  const [isValid, setIsValid] = useState(false);
  const [answers, setAnswers] = useState({
    newsletterId: null,
    prefix: null,
    firstname: '',
    lastname: '',
    dob: null,
    answers_1: [],
    answers_2: [],
    answers_3: []
  });
  let error = false;

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const confirmCode = urlParams.get('confirm_code');
    const subscriberId = urlParams.get('subscriber_id');

    getUserIp().then(ipData => {
      const remoteIp = ipData.ip;

      confirmNewsletter({
        variables: {
          remoteIp,
          subscriberId,
          confirmCode
        }
      }).then(({ data }) => {
        if (data.confirmNewsletter) {
          if (!data.confirmNewsletter.success) {
            error = true;
          } else {
            const currentAnswers = answers;
            const subscriberInfo = data.confirmNewsletter.subscriberInfo || null;
            setAnswers({
              ...currentAnswers,
              newsletterId: subscriberInfo?.newsletterId ? subscriberInfo.newsletterId : null,
              prefix: subscriberInfo?.prefix ? subscriberInfo.prefix : null,
              firstname: subscriberInfo?.firstname ? subscriberInfo.firstname : '',
              lastname: subscriberInfo?.lastname ? subscriberInfo.lastname : '',
              dob: subscriberInfo?.dob ? subscriberInfo.dob : null
            });
            setLoading(false);
          }
        }
      });
    });
  }, []);

  return (
    <SetSaveSubscriptionInfoMutation>
      {setSaveSubscriptionInfo => (
        <I18n>
          {t => {
            if (error) {
              dispatch({
                type: 'ADD_TO_STATE',
                section: 'messages',
                data: {
                  content: t('newsletter.subscriberConfirmError'),
                  type: 'error'
                }
              });
            }
            const breadcrumbs = [{ name: t('newsletter.signIn'), urlPath: '#' }];
            let submit = null;

            const bindSubmitForm = submitForm => {
              submit = submitForm;
            };

            const handlePreviousStep = () => {
              const step = currentStep;
              setCurrentStep(step - 1);
            };

            const handleAnswerClick = (elem, item, stepId) => {
              const currentAnswers = answers;
              const isChecked = elem.currentTarget.checked;
              if (isChecked) {
                const stepAnswers = answers[`answers_${stepId}`];
                setAnswers({
                  ...currentAnswers,
                  [`answers_${stepId}`]: [...stepAnswers, encodeURIComponent(item.id)]
                });
              } else {
                const stepAnswers = answers[`answers_${stepId}`].filter(e => e !== item.id);
                setAnswers({ ...currentAnswers, [`answers_${stepId}`]: stepAnswers });
              }
            };

            if (loading) {
              return <Preloader />;
            }

            if (moduleEnabled === '0') {
              return <NewsletterSubscriberConfirm />;
            }

            return (
              <NewsletterSubscriptionStepsQuery>
                {({ data: { newsletterSubscriptionSteps }, loading: stepsLoading }) => {
                  let steps;

                  if (!stepsLoading) {
                    steps = Object.keys(newsletterSubscriptionSteps);
                    steps = steps.filter(step => {
                      if (newsletterSubscriptionSteps[step].question) {
                        if (!newsletterSubscriptionSteps[step].answers.length) {
                          return false;
                        }
                      }

                      return step !== '__typename';
                    });
                  }

                  const handleNextStep = e => {
                    const step = currentStep;
                    const queryString = window.location.search;
                    const urlParams = new URLSearchParams(queryString);
                    const email = urlParams.get('mail');
                    const confirmCode = urlParams.get('confirm_code');
                    const subscriberId = urlParams.get('subscriber_id');

                    // generate selected values array for the newsletter tracking (real values instead of ids)
                    let selectedValues;
                    if (currentStep !== steps.length) {
                      if (currentStep > 1 && currentStep < steps.length) {
                        const lastStep = currentStep - 1;
                        selectedValues = newsletterSubscriptionSteps[`question${lastStep}`].answers.filter(
                          filteredAnswers => answers[`answers_${lastStep}`].includes(filteredAnswers.id)
                        );
                        selectedValues = selectedValues.map(mappedSelectedValues => mappedSelectedValues.option);
                      } else {
                        selectedValues = { dob: answers.dob, prefix: answers.prefix };
                      }
                      NewsletterStepsTracking({ storeConfiguration, step: currentStep, selectedValues });
                    }

                    switch (currentStep) {
                      case 1:
                        if (submit) {
                          submit(e).then(() => {
                            setCurrentStep(step + 1);
                          });
                        }
                        break;
                      case steps.length - 1:
                        setSaveSubscriptionInfo({
                          variables: {
                            ...answers,
                            newsletter_id: `${answers.newsletterId}`,
                            email,

                            subscriberId,
                            confirmCode
                          }
                        });
                        setCurrentStep(step + 1);
                        break;
                      default:
                        setCurrentStep(step + 1);
                    }
                  };

                  return (
                    <React.Fragment>
                      {stepsLoading ? (
                        <Preloader cover transparent />
                      ) : (
                        <Creator
                          breadcrumbs={breadcrumbs}
                          title={newsletterSubscriptionSteps[steps[currentStep - 1]].label}
                          subtitle={newsletterSubscriptionSteps[steps[currentStep - 1]].description}
                          currentStep={currentStep}
                          totalStepsCount={steps.length}
                          isValid={isValid}
                          handleNextStep={handleNextStep}
                          handlePreviousStep={handlePreviousStep}
                          summaryLink="/"
                          summaryLinkName={t('newsletterCreator.homepage')}
                        >
                          {currentStep === 1 && (
                            <React.Fragment>
                              <NewsletterCreatorFirstStep
                                step={newsletterSubscriptionSteps[steps[currentStep - 1]]}
                                bindSubmitForm={bindSubmitForm}
                                setIsValid={setIsValid}
                                answers={answers}
                                setAnswers={setAnswers}
                              />
                            </React.Fragment>
                          )}
                          {currentStep > 1 && currentStep < steps.length && (
                            <NewsletterCreatorQuestionStep
                              step={newsletterSubscriptionSteps[steps[currentStep - 1]]}
                              stepId={currentStep}
                              answers={answers}
                              handleAnswerClick={handleAnswerClick}
                            />
                          )}
                          {currentStep === steps.length && (
                            <NewsletterCreatorConfirmationStep
                              step={newsletterSubscriptionSteps[steps[currentStep - 1]]}
                            />
                          )}
                        </Creator>
                      )}
                    </React.Fragment>
                  );
                }}
              </NewsletterSubscriptionStepsQuery>
            );
          }}
        </I18n>
      )}
    </SetSaveSubscriptionInfoMutation>
  );
});

export default NewsletterCreator;
