import React from 'react';
import { Formik } from 'formik';
import { Form } from '@deity/falcon-ui-kit';
import { I18n } from '@deity/falcon-i18n';
import { Day, Month, Year } from '../../../helpers/Date/Date';
import { Dropdown } from '../../../ui/Dropdown/Dropdown';
import { Radio } from '../../../ui/Radio/Radio';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';
import { FormField } from '../../../ui/Forms/FormField';
import styles from './NewsletterCreatorSteps.module.scss';

const NewsletterCreatorFirstStep = withStoreConfiguration(
  ({ storeConfiguration, step, bindSubmitForm, setIsValid, answers, setAnswers }) => {
    const getDate = string => {
      const date = (([year, month, day]) => ({ day, month, year }))(string.split('-'));
      if (date.day.startsWith('0')) {
        date.day = date.day.substring(1);
      }
      return date;
    };
    const prefixOptions = storeConfiguration?.customer?.address?.prefix_options?.split(';');
    const checkIfInitialIsValid = () => {
      return !!answers.firstname && !!answers.lastname && !!answers.dob;
    };

    const saveValues = values => {
      const submitDob = values.dobYear ? `${values.dobYear}-${values.dobMonth}-${values.dobDay}` : null;
      setAnswers({
        ...answers,
        prefix: values.prefix,
        firstname: values.firstname,
        lastname: values.lastname,
        dob: submitDob
      });
    };

    return (
      <I18n>
        {t => {
          const months = [...Month].map(item => {
            item.label = t(item.label);
            return item;
          });
          Day[0].label = t(Day[0].label);
          Year[0].label = t(Year[0].label);

          let dob;
          if (answers.dob) {
            dob = getDate(answers.dob);
          } else {
            dob = { day: null, month: null, year: null };
          }

          return (
            <React.Fragment>
              <Formik
                isInitialValid={checkIfInitialIsValid()}
                initialValues={{
                  prefix: answers.prefix ? answers.prefix : null,
                  firstname: answers.firstname ? answers.firstname : '',
                  lastname: answers.lastname ? answers.lastname : '',
                  dobDay: dob.day,
                  dobMonth: dob.month,
                  dobYear: dob.year
                }}
                onSubmit={saveValues}
                validate={saveValues}
              >
                {props => {
                  const { submitForm, isValid } = props;
                  bindSubmitForm(submitForm);
                  setIsValid(isValid);

                  return (
                    <Form id="newsletter-creator" i18nId="newsletterCreator">
                      <div className={styles.CreatorQuestion}>{step.text1}</div>
                      <FormField name="prefix" className={styles.RadioWrapper}>
                        {({ form, field }) => {
                          return prefixOptions.map(item => (
                            <Radio
                              key={item}
                              name="namePrefix"
                              value={item}
                              checked={field.value === item}
                              onChange={x => form.setFieldValue(field.name, x.target.value)}
                              label={item}
                            />
                          ));
                        }}
                      </FormField>
                      <div className={styles.PersonWrapper}>
                        <FormField
                          name="firstname"
                          placeholder={t('addAddress.firstnameFieldPlaceholder')}
                          required
                          showErrorMessage
                        />
                        <FormField
                          name="lastname"
                          placeholder={t('addAddress.lastnameFieldPlaceholder')}
                          required
                          showErrorMessage
                        />
                      </div>
                      <div className={styles.CreatorQuestion}>{step.text2}</div>
                      <div className={styles.FormDate}>
                        <FormField required name="dobDay">
                          {({ form, field }) => (
                            <Dropdown
                              narrowerMobile
                              invalid={field.value === null}
                              firstOptionAsLabel
                              name="dobDay"
                              options={Day}
                              preSelected={dob.day}
                              onChange={x => form.setFieldValue(field.name, x)}
                            />
                          )}
                        </FormField>

                        <FormField required name="dobMonth">
                          {({ form, field }) => (
                            <Dropdown
                              narrowerMobile
                              invalid={field.value === null}
                              firstOptionAsLabel
                              name="dobMonth"
                              options={months}
                              preSelected={dob.month}
                              onChange={x => form.setFieldValue(field.name, x)}
                            />
                          )}
                        </FormField>

                        <FormField required name="dobYear">
                          {({ form, field }) => (
                            <Dropdown
                              narrowerMobile
                              invalid={field.value === null}
                              firstOptionAsLabel
                              name="dobYear"
                              options={Year}
                              preSelected={dob.year}
                              onChange={x => form.setFieldValue(field.name, x)}
                            />
                          )}
                        </FormField>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </React.Fragment>
          );
        }}
      </I18n>
    );
  }
);

export default NewsletterCreatorFirstStep;
