import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Container from '../../layout/Container/Container';
import { Breadcrumbs } from '../Breadcrumbs/Breadcrumbs';
import bg from '../../../../../assets/images/newsletter/bg.jpg';
import bgMobile from '../../../../../assets/images/newsletter/bg-mobile.jpg';
import svgSprite from '../../../../../assets/images/sprites/sprites.svg';
import useCheckBreakpoints from '../../hooks/CheckBreakpoint';
import styles from './BackgroundContainer.module.scss';

const BackgroundContainer = props => {
  const { isDesktop } = useCheckBreakpoints();
  const { className, breadcrumbs, title, subtitle, width, ...restProps } = props;
  const background = useMemo(() => {
    if (!isDesktop) {
      return bgMobile;
    }
    return bg;
  }, [isDesktop]);

  const inlineStyles = {};

  if (width) {
    inlineStyles.width = width;
  }

  return (
    <div className={styles.Background}>
      <Container className={styles.TitleWrapper} background={background} clearOnMobile>
        <div className={styles.Title}>
          <img src={`${svgSprite}#ornamentLeft`} width="178" height="64" alt="ornamentLeft" />
          <span style={inlineStyles}>{title}</span>
          <img src={`${svgSprite}#ornamentRight`} width="178" height="64" alt="ornamentRight" />
        </div>
        {subtitle ? <div className={styles.Subtitle}>{subtitle}</div> : null}
      </Container>

      <div className={styles.Gradient}>
        <Container clearOnMobile>
          <div className={styles.Box}>
            {props.breadcrumbs ? <Breadcrumbs breadcrumbs={breadcrumbs} /> : null}
            <div className={styles.Content}>
              <div className={[className].join(' ')} {...restProps}>
                {props.children}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default BackgroundContainer;

BackgroundContainer.propTypes = {
  /** Background container class name */
  className: PropTypes.string,
  /** Background container page breadcrumb */
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      urlPath: PropTypes.string,
      name: PropTypes.string
    })
  ),
  /** Background container title */
  title: PropTypes.string,
  /** Background container subtitle */
  subtitle: PropTypes.string,
  /** Background container title width */
  width: PropTypes.string
};
