import React from 'react';
import styles from './NewsletterCreatorSteps.module.scss';

const NewsletterCreatorQuestionStep = ({ step, stepId, answers, handleAnswerClick }) => {
  const answerStepId = stepId - 1;
  return (
    <div className={styles.AlignCenter}>
      <div className={styles.CreatorQuestion}>{step.question}</div>
      <div className={styles.CreatorQuestionDescription}>{step.questionDescription}</div>
      <div className={styles.CreatorAnswers}>
        {step.answers.map((item, index) => {
          return (
            <div
              className={styles.CreatorAnswer}
              key={`question1-${index}`} // eslint-disable-line react/no-array-index-key
            >
              <div className={styles.CreatorAnswerWrapper}>
                <input
                  name={item.id}
                  type="checkbox"
                  id={`question${stepId}-${item.id}`}
                  checked={answers[`answers_${answerStepId}`].includes(encodeURIComponent(item.id))}
                  onChange={elem => handleAnswerClick(elem, item, answerStepId)}
                />
                <label
                  className={[styles.CreatorAnswerLabel, 'after-icon-check'].join(' ')}
                  htmlFor={`question${stepId}-${item.id}`}
                >
                  {item.option}
                </label>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NewsletterCreatorQuestionStep;
