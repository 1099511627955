import React from 'react';
import { I18n } from '@deity/falcon-i18n';
import BackgroundContainer from '../../elements/BackgroundContainer/BackgroundContainer';
import NewsletterNotConfirmedInfo from '../../sections/Newsletter/NewsletterNotConfirmedInfo';
import NewsletterPrivacy from '../../sections/Newsletter/NewsletterPrivacy';
import { GoogleTagManager4Pageview } from '../../elements/Tracking/GoogleTagManager';

const NewsletterNotConfirmed = () => {
  return (
    <I18n>
      {t => {
        const breadcrumbs = [{ name: t('newsletter.signIn'), urlPath: '#' }];
        return (
          <BackgroundContainer title={t('newsletterNotConfirmed.title')} breadcrumbs={breadcrumbs}>
            <NewsletterNotConfirmedInfo />
            <NewsletterPrivacy variant="noBox" />
            <GoogleTagManager4Pageview
              forcePush
              dataLayerData={{ page_type: 'conversion_lead', page_title: t('newsletterNotConfirmed.title') }}
            />
          </BackgroundContainer>
        );
      }}
    </I18n>
  );
};

export default NewsletterNotConfirmed;
