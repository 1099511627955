import React from 'react';
import PropTypes from 'prop-types';
import styles from './Box.module.scss';

export const Box = props => {
  const { title, corner, className, border, ...restProps } = props;
  const additionalClasses = [];

  if (border) {
    if (border === 'gray') {
      additionalClasses.push(styles.BoxBorderGray);
    } else {
      additionalClasses.push(styles.BoxBorder);
    }
  }

  return (
    <div className={[styles.Box, ...additionalClasses].join(' ')} {...restProps}>
      {title || corner ? (
        <h3 className={styles.BoxTitle}>
          {title}
          {corner ? <span className={styles.BoxCorner}>{corner}</span> : null}
        </h3>
      ) : null}
      <div className={[styles.BoxContent, className].join(' ')}>{props.children}</div>
    </div>
  );
};

Box.propTypes = {
  /** Box title */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /** Box corner content */
  corner: PropTypes.node,
  /** Box content class name */
  className: PropTypes.string,
  /** Set if box should have border */
  border: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};
