import { I18n, T } from '@deity/falcon-i18n';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { InnerHTML } from '@deity/falcon-ui-kit';
import newsletterMobileBg from '../../../../../assets/images/home/newsletter-background-mobile.jpg';
import newsletterBg from '../../../../../assets/images/home/newsletter-background.jpg';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import Container from '../../layout/Container/Container';
import useCheckBreakpoints from '../../hooks/CheckBreakpoint';
import loadable from '../../../../../components/loadable';
import styles from './Newsletter.module.scss';
import { NewsletterInput } from './NewsletterInput';

const Modal = loadable(() => import(/* webpackChunkName: "general" */ '../../ui/Modal/Modal'));

const Newsletter = withRouter(
  withStoreConfiguration(({ hideMobile, storeConfiguration }) => {
    const { isDesktop } = useCheckBreakpoints();
    const [success, setSuccess] = useState(false);
    const [showAgbOverlay, setShowAgbOverlay] = useState(false);
    const [showDataPrivacy, setShowDataPrivacy] = useState(false);
    const newsletterTitle = storeConfiguration?.asam_newsletterregistration?.newsletter?.title;
    const newsletterDescription = storeConfiguration?.asam_newsletterregistration?.newsletter?.description;
    if (storeConfiguration?.newsletter?.general?.active !== '1') {
      return null;
    }
    return (
      <div
        data-nosnippet
        className={[
          'SpaceToTop',
          styles.Newsletter,
          success ? styles.NewsletterSuccessful : null,
          hideMobile ? styles.NewsletterHide : null
        ].join(' ')}
      >
        <Container background={isDesktop ? newsletterBg : newsletterMobileBg}>
          <div className={styles.NewsletterTitle}>{newsletterTitle}</div>
          {!success ? (
            <React.Fragment>
              <InnerHTML className={styles.NewsletterText} html={newsletterDescription} />
              <NewsletterInput setSuccess={setSuccess} touchpoint="footer" />
            </React.Fragment>
          ) : (
            <div className={styles.NewsletterSuccess}>
              <div className={styles.NewsletterSuccessHeader}>
                <T id="newsletter.successHeader" />
              </div>
              <div className={styles.NewsletterSuccessText}>
                <T id="newsletter.successText" />
              </div>
            </div>
          )}
          <I18n>
            {t => (
              <React.Fragment>
                <div className={styles.NewsletterTextAdditional}>
                  <T id="newsletter.textAdditionalBefore" />
                  {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                  <span onClick={() => setShowAgbOverlay(true)}>{t('newsletter.textAdditionalFirstLink')}</span>
                  <T id="newsletter.textAdditionalMiddle" />
                  {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                  <span onClick={() => setShowDataPrivacy(true)}>{t('newsletter.textAdditionalSecondLink')}</span>.
                </div>
                {showAgbOverlay ? (
                  <Modal
                    pageContent={t('cms.agb.link')}
                    variant="tertiary"
                    scrollableContent
                    visible={showAgbOverlay}
                    close={() => {
                      setShowAgbOverlay(false);
                    }}
                  />
                ) : null}
                {showDataPrivacy ? (
                  <Modal
                    pageContent={t('cms.dataprotection.link')}
                    variant="tertiary"
                    scrollableContent
                    visible={showDataPrivacy}
                    close={() => {
                      setShowDataPrivacy(false);
                    }}
                  />
                ) : null}
              </React.Fragment>
            )}
          </I18n>
        </Container>
      </div>
    );
  })
);

export default Newsletter;

Newsletter.defaultProps = {
  hideMobile: false
};

Newsletter.propTypes = {
  /** If true newsletter won't be displayed on mobile size */
  hideMobile: PropTypes.bool
};
