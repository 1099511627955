import React, { useEffect, useState } from 'react';
import { T, I18n } from '@deity/falcon-i18n';
import { Loader } from '@deity/falcon-ui-kit';
import BackgroundContainer from '../../elements/BackgroundContainer/BackgroundContainer';
import { useGlobalStateValue } from '../../helpers/GlobalState/GlobalState';
import {
  RemoveCustomerNewsletterMutation,
  RemoveNewsletterMutation
} from '../../sections/Newsletter/NewsletterMutation';
import { getUserIp } from '../../elements/Geoip/Geoip';
import { CustomerQuery } from '../../overrides/Customer/CustomerQuery';
import { NewsletterCancellationTracking } from '../../elements/Tracking/GoogleTagManager';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import { Errors } from '../../elements/Errors/Errors';

const NewsletterUnsubscribe = withStoreConfiguration(({ storeConfiguration, history }) => {
  const [, dispatch] = useGlobalStateValue();
  const [subscriberId, setSubscriberId] = useState(false);
  const [customerId, setCustomerId] = useState(false);
  const [token, setToken] = useState('');
  const [paramsLoaded, setParamsLoaded] = useState(false);
  const [functionLoaded, setFunctionLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    setSubscriberId(urlParams.get('subscriber_id'));
    setCustomerId(urlParams.get('customer_id'));
    setToken(urlParams.get('token'));
    setParamsLoaded(true);
  }, []);

  const handleMessage = (content, type) => {
    window.scrollBy(0, -10000);
    dispatch({
      type: 'ADD_TO_STATE',
      section: 'messages',
      data: {
        content,
        type
      }
    });
  };

  return (
    <I18n>
      {t => {
        const breadcrumbs = [{ name: t('newsletter.signIn'), urlPath: '#' }];
        return (
          <BackgroundContainer title={t('newsletter.unsubscribe')} breadcrumbs={breadcrumbs}>
            <RemoveCustomerNewsletterMutation>
              {(removeCustomerNewsletter, { loading: loadingCustomer }) => (
                <RemoveNewsletterMutation>
                  {(removeNewsletter, { loading: loadingGuest }) => (
                    <CustomerQuery fetchPolicy="network-only" passLoading>
                      {({ data: { customer }, loading: loadingSignIn }) => {
                        const silentRemove = () => {
                          if (paramsLoaded && !functionLoaded) {
                            if (customer && !customerId && !subscriberId) {
                              getUserIp().then(ipData => {
                                const remoteIp = ipData.ip;
                                removeCustomerNewsletter({
                                  variables: { remoteIp }
                                }).then(({ data, errors }) => {
                                  const response = data.removeCustomerNewsletter;
                                  if (response) {
                                    if (response.success) {
                                      handleMessage(response.message, 'success');

                                      NewsletterCancellationTracking({ storeConfiguration });
                                      // history.replace('/');
                                    }
                                    if (!response.success && response.message) {
                                      handleMessage(response.message, 'error');
                                      setErrorMessage(errors || response.message);
                                    }

                                    setShowMessage(response.success);
                                  }
                                });
                              });
                            } else if (customerId || subscriberId) {
                              getUserIp().then(ipData => {
                                const remoteIp = ipData.ip;
                                removeNewsletter({
                                  variables: { remoteIp, customerId, subscriberId, token }
                                }).then(({ data, errors }) => {
                                  const response = data.removeNewsletter;
                                  if (response) {
                                    if (response.success) {
                                      handleMessage(t('newsletter.unsubscribeSuccess'), 'success');

                                      NewsletterCancellationTracking({ storeConfiguration });
                                      // history.replace('/');
                                    }
                                    if (!response.success) {
                                      handleMessage(t('newsletter.unsubscribeError'), 'error');
                                      setErrorMessage(errors || response.message);
                                    }

                                    setShowMessage(response.success);
                                  }
                                });
                              });
                            } else if (paramsLoaded === true && loadingSignIn === false) {
                              // redirect to contact form (ITW-2659)
                              history.replace(`/${t('cms.contact.link')}`);
                            }
                            setFunctionLoaded(true);
                          }
                        };

                        if (loadingCustomer || loadingGuest) {
                          return <Loader />;
                        }
                        if (customer || customerId || subscriberId) {
                          silentRemove();
                        }

                        if (showMessage) {
                          return (
                            <div>
                              <p>
                                <T id="newsletter.unsubscribe1" />
                              </p>
                              <p>
                                <T id="newsletter.unsubscribe2" />
                              </p>
                              <p>
                                <T id="newsletter.unsubscribe3" />
                              </p>
                              <p>
                                <T id="newsletter.unsubscribe4" />
                              </p>
                            </div>
                          );
                        }

                        return <Errors error={errorMessage} noPadding />;
                      }}
                    </CustomerQuery>
                  )}
                </RemoveNewsletterMutation>
              )}
            </RemoveCustomerNewsletterMutation>
          </BackgroundContainer>
        );
      }}
    </I18n>
  );
});

export default NewsletterUnsubscribe;
