import React from 'react';
import { T } from '@deity/falcon-i18n';
import { Logo } from '../../elements/Logo/Logo';
import { Button } from '../../ui/Button/Button';
import generalSVG from '../../../../../assets/images/sprites/generalSprite.svg';
import styles from './NewsletterNotConfirmedInfo.module.scss';

const NewsletterNotConfirmedInfo = () => {
  return (
    <div className={styles.NotConfirmed}>
      <p>
        <T id="accountNewsletter.NotConfirmedDesc1" />
      </p>
      <p>
        <T id="accountNewsletter.NotConfirmedDesc2" />
      </p>
      <p className={styles.Bold}>
        <T id="accountNewsletter.NotConfirmedDesc3" />
      </p>
      <div className={[styles.Box, styles.EmailBox].join(' ')}>
        <div className={styles.EmailBoxColumn}>
          <div className={[styles.Box, styles.BoxRounded].join(' ')} />
          <div className={styles.Inbox}>
            <T id="accountNewsletter.NotConfirmedInbox" /> (1)
          </div>
          <ul className={styles.BoxList}>
            <li className={styles.Box} />
            <li className={styles.Box} />
            <li className={styles.Box} />
            <li className={styles.Box} />
            <li className={styles.Box} />
            <li className={styles.Box} />
          </ul>
        </div>
        <div className={[styles.EmailBoxColumn, styles.EmailBoxColumnContent].join(' ')}>
          <div className={styles.Logo}>
            <Logo />
          </div>
          <p>
            <T id="accountNewsletter.NotConfirmedEmail1" />
          </p>
          <div className={styles.Button}>
            <Button>
              <T id="accountNewsletter.NotConfirmedEmailButton" />
            </Button>
          </div>
          <p className={styles.Gradient}>
            <T id="accountNewsletter.NotConfirmedEmail2" />
          </p>
        </div>
        <div className={styles.Pointer}>
          <div className={styles.ButtonPointer}>
            <img src={`${generalSVG}#buttonPointer`} alt="point here" />
          </div>
          <p>
            <T id="accountNewsletter.NotConfirmedConfirm" />
          </p>
          <div className={styles.ButtonPointerTop}>
            <img src={`${generalSVG}#buttonPointerTop`} alt="point here" />
          </div>
        </div>
      </div>
      <p>
        <T id="accountNewsletter.NotConfirmedDesc4" />
      </p>
      <p className={styles.Bold}>
        <T id="accountNewsletter.NotConfirmedDesc5" />
      </p>
    </div>
  );
};

export default NewsletterNotConfirmedInfo;
