import React from 'react';
import PropTypes from 'prop-types';
import styles from './CreatorProgressBar.module.scss';

export const CreatorProgressBar = ({ totalStepsCount, currentStep }) => {
  const steps = [...Array(totalStepsCount).keys()];

  return (
    <div className={styles.CreatorProgressBar}>
      {steps.map(item => {
        const additionalClasses = [];

        if (item + 1 === currentStep) {
          additionalClasses.push(styles.CreatorProgressBarItemCurrent);
        }

        if (item + 1 <= currentStep) {
          additionalClasses.push(styles.CreatorProgressBarItemActive);
        }

        return (
          <div className={[styles.CreatorProgressBarItem, ...additionalClasses].join(' ')} key={`bar${item}`}>
            <div className={styles.CreatorProgressBarItemCount}>{item + 1}</div>
          </div>
        );
      })}
    </div>
  );
};

CreatorProgressBar.propTypes = {
  /** Creator current step */
  currentStep: PropTypes.number,
  /** Creator total steps count */
  totalStepsCount: PropTypes.number
};
