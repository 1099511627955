import React from 'react';
import { T } from '@deity/falcon-i18n';
import PropTypes from 'prop-types';
import BackgroundContainer from '../BackgroundContainer/BackgroundContainer';
import { Button } from '../../ui/Button/Button';
import { CreatorProgressBar } from './CreatorProgressBar/CreatorProgressBar';
import styles from './Creator.module.scss';

export const Creator = props => {
  const {
    title,
    subtitle,
    breadcrumbs,
    currentStep,
    totalStepsCount,
    handleNextStep,
    handlePreviousStep,
    isValid,
    summaryLink,
    summaryLinkName
  } = props;

  return (
    <BackgroundContainer title={title} subtitle={subtitle} breadcrumbs={breadcrumbs}>
      {currentStep !== totalStepsCount ? (
        <CreatorProgressBar currentStep={currentStep} totalStepsCount={totalStepsCount - 1} />
      ) : null}
      <div className={styles.CreatorContent}>{props.children}</div>
      {currentStep !== totalStepsCount ? (
        <div
          className={
            currentStep !== 1 ? styles.CreatorNavigation : [styles.CreatorNavigation, styles.CreatorButton].join(' ')
          }
        >
          {currentStep !== 1 ? (
            <Button types="ghost" onClick={() => handlePreviousStep()}>
              <T id="creator.prev" />
            </Button>
          ) : null}
          <Button onClick={() => handleNextStep(() => handleNextStep())} disabled={!isValid}>
            <T id="creator.next" />
          </Button>
        </div>
      ) : null}
      {currentStep === totalStepsCount && (
        <div className={[styles.CreatorNavigation, styles.CreatorNavigationCenter].join(' ')}>
          <Button asLink path={summaryLink}>
            {summaryLinkName}
          </Button>
        </div>
      )}
    </BackgroundContainer>
  );
};

Creator.propTypes = {
  /** Creator title */
  title: PropTypes.string,
  /** Creator subtitle */
  subtitle: PropTypes.string,
  /** Creator page breadcrumb */
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      urlPath: PropTypes.string,
      name: PropTypes.string
    })
  ),
  /** Creator current step */
  currentStep: PropTypes.number,
  /** Creator total steps count */
  totalStepsCount: PropTypes.number,
  /** Creator next step function */
  handleNextStep: PropTypes.func,
  /** Creator prev step function */
  handlePreviousStep: PropTypes.func,
  /** Creator validation */
  isValid: PropTypes.bool,
  /** Creator summary link */
  summaryLink: PropTypes.string,
  /** Creator summary link text */
  summaryLinkName: PropTypes.string
};
