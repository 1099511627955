import React from 'react';
import styles from './NewsletterCreatorSteps.module.scss';

const NewsletterCreatorConfirmationStep = ({ step }) => {
  return (
    <div className={styles.AlignCenter}>
      <div className={styles.CreatorSummaryLabel}>{step.summaryLabel}</div>
      <div className={styles.CreatorSummaryDescription}>{step.summaryDescription}</div>
    </div>
  );
};

export default NewsletterCreatorConfirmationStep;
