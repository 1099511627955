import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { I18n } from '@deity/falcon-i18n';
import { Box } from '../../elements/Box/Box';
import loadable from '../../../../../components/loadable';
import styles from './NewsletterPrivacy.module.scss';

const Modal = loadable(() => import(/* webpackChunkName: "general" */ '../../ui/Modal/Modal'));

const NewsletterPrivacy = ({ variant, notRegistered }) => {
  let linkClass;
  if (variant === 'noBox') {
    linkClass = 'Link';
  } else {
    linkClass = 'Link LinkPurple LinkLarge';
  }
  const [showAgbOverlay, setShowAgbOverlay] = useState(false);
  const [showDataProtectionOverlay, setShowDataProtectionOverlay] = useState(false);

  const content = (
    <I18n>
      {t => (
        <span className="normal">
          {notRegistered ? t('newsletter.textAdditionalBefore') : t('accountNewsletter.Privacy')}
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <span className={linkClass} onClick={() => setShowAgbOverlay(true)}>
            {notRegistered ? t('newsletter.textAdditionalFirstLink') : t('accountNewsletter.PrivacyTerms')}
          </span>
          {notRegistered ? t('newsletter.textAdditionalMiddle') : t('accountNewsletter.PrivacyAnd')}
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <span className={linkClass} onClick={() => setShowDataProtectionOverlay(true)}>
            {notRegistered ? t('newsletter.textAdditionalSecondLink') : t('accountNewsletter.PrivacyPolicy')}.
          </span>
        </span>
      )}
    </I18n>
  );

  return (
    <I18n>
      {t => (
        <React.Fragment>
          {variant === 'noBox' && <div className={styles.NewsletterPrivacyNoBox}>{content}</div>}
          {variant !== 'noBox' && <Box className={styles.NewsletterPrivacy}>{content}</Box>}
          {showAgbOverlay ? (
            <Modal
              pageContent={t('cms.agb.link')}
              variant="tertiary"
              scrollableContent
              visible={showAgbOverlay}
              close={() => {
                setShowAgbOverlay(false);
              }}
            />
          ) : null}
          {showDataProtectionOverlay ? (
            <Modal
              pageContent={t('cms.dataprotection.link')}
              variant="tertiary"
              scrollableContent
              visible={showDataProtectionOverlay}
              close={() => {
                setShowDataProtectionOverlay(false);
              }}
            />
          ) : null}
        </React.Fragment>
      )}
    </I18n>
  );
};

export default NewsletterPrivacy;

NewsletterPrivacy.propTypes = {
  /** set if newsletter privacy should be displayed in grey box */
  variant: PropTypes.oneOf(['noBox'])
};
