import { Query } from '@deity/falcon-data';
import gql from 'graphql-tag';

export const GET_SUBSCRIPTION_DATA = gql`
  query NewsletterSubscriptionSteps {
    newsletterSubscriptionSteps {
      userInfo {
        label
        description
        text1
        text2
      }
      question1 {
        label
        description
        question
        questionDescription
        answers {
          id
          option
        }
      }
      question2 {
        label
        description
        question
        questionDescription
        answers {
          id
          option
        }
      }
      question3 {
        label
        description
        question
        questionDescription
        answers {
          id
          option
        }
      }
      summary {
        label
        description
        summaryLabel
        summaryDescription
      }
    }
  }
`;

export const GET_SUBSCRIPTION_INFO = gql`
  query NewsletterSubscriptionInfo($subscriptionId: String!) {
    newsletterSubscriptionInfo(subscriptionId: $subscriptionId) {
      firstname
      lastname
      dob
      answers_1
      answers_2
      answers_3
    }
  }
`;

export class NewsletterSubscriptionStepsQuery extends Query {
  static defaultProps = {
    fetchPolicy: 'no-cache',
    query: GET_SUBSCRIPTION_DATA,
    notifyOnNetworkStatusChange: true,
    passLoading: true
  };
}

export class NewsletterSubscriptionInfoQuery extends Query {
  static defaultProps = {
    fetchPolicy: 'network-only',
    query: GET_SUBSCRIPTION_INFO,
    notifyOnNetworkStatusChange: true,
    passLoading: true,
    errorPolicy: 'ignore'
  };
}
