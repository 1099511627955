import { Mutation } from '@deity/falcon-data';
import gql from 'graphql-tag';

export const SET_SAVE_SUBSCRIPTION_INFO = gql`
  mutation SetSaveSubscriptionInfo(
    $newsletter_id: String!
    $firstname: String
    $lastname: String
    $prefix: String
    $dob: String
    $email: String
    $subscriberId: String
    $confirmCode: String
    $customer_id: Int
    $answers_1: [String]
    $answers_2: [String]
    $answers_3: [String]
  ) {
    setSaveSubscriptionInfo(
      newsletterId: $newsletter_id
      firstname: $firstname
      lastname: $lastname
      prefix: $prefix
      dob: $dob
      subscriberId: $subscriberId
      confirmCode: $confirmCode
      email: $email
      customerId: $customer_id
      answers_1: $answers_1
      answers_2: $answers_2
      answers_3: $answers_3
    ) {
      success
      message
    }
  }
`;

export class SetSaveSubscriptionInfoMutation extends Mutation {
  static defaultProps = {
    mutation: SET_SAVE_SUBSCRIPTION_INFO,
    refetchQueries: ['NewsletterStatus', 'NewsletterSubscriptionSteps', 'NewsletterSubscriptionInfo', 'Customer']
  };
}
